import { initializeApp } from "firebase/app";
import
  {
    browserLocalPersistence,
    getAuth,
    setPersistence,
  } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyC1MDrWkUWN-9IVQN-HdOyqnT62OoeX_iI",
  authDomain: "cmpnd-sit.firebaseapp.com",
  projectId: "cmpnd-sit",
  storageBucket: "cmpnd-sit.appspot.com",
  messagingSenderId: "59441596236",
  appId: "1:59441596236:web:b2eb8735fbf209af54f1e9",
  measurementId: "G-Q6BGE0DX1Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(app);
setPersistence(firebaseAuth, browserLocalPersistence);
